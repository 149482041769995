// assets/styles/global.scss

// customize some Bootstrap variables
// $primary: darken(#3b78b1, 20%);
// $light: #E0E1DB;
// $light: #E4D2AC;
// $light: #BDB6C4;
$light: #D0B5A8;

// the ~ allows you to reference things in node_modules
@import "~bootstrap/scss/bootstrap";


body {
    background-color: $light;
}


.navbar-nav > li > .dropdown-menu { 
    background-color: $light; 
}

a:hover {
    background-color: $light !important;
    color: black !important;
}

.mentionsLegalesContainer {
    max-width: 30rem;
    height: 70rem;
}