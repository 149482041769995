@font-face {
    font-family: 'PlaywriteESDeco';
    src: url('/assets/styles/fonts/PlaywriteESDeco-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'PlaywriteDEGrund';
    src: url('/assets/styles/fonts/PlaywriteDEGrund-VariableFont_wght.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'bold';
    src: url('/assets/styles/fonts/static/NotoSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'boldManuscrit';
    src: url('/assets/styles/fonts/Playwrite_DE_Grund/static/PlaywriteDEGrund-Regular.ttf') format('truetype');
}

.titre1 {
    font-family: 'boldManuscrit', sans-serif;
    font-weight: 700 !important;
}

.boldFont {
    font-family: 'bold', sans-serif;
}

.boldFontManuscrit {
    font-family: 'boldManuscrit', sans-serif;
    font-weight: 700 !important;
    // font-size: calc(1.2rem + 1.2vw);
}

.texteManuscrit {
    font-family: 'PlaywriteESDeco', sans-serif;
}

.texteNormalFont {
    font-family: 'PlaywriteDEGrund', sans-serif;
    font-size: calc(1.2rem + 0.1vw);
}

.nav-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}
